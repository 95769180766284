html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.safe-mode *[data-safe] {
  filter: blur(0.3rem) !important;
}

.safe-mode *[data-safe]:hover {
  filter: blur(0.3rem) !important;
}

.safe-mode *[data-safe]:active {
  filter: blur(0.3rem) !important;
}

.safe-mode *[data-safe]:focus {
  filter: blur(0.3rem) !important;
}

.custom-zoom [data-rmiz-modal-overlay='hidden'] {
  background-color: rgb(56, 58, 89, 0);
}
.custom-zoom [data-rmiz-modal-overlay='visible'] {
  background-color: rgb(56, 58, 89, 0.5);
}
